var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "rubik flex justify-center items-center container",
      class:
        !_vm.isStudio && _vm.defaultVideoNetworkType === "VIDEO_FREE"
          ? "custom-bg-blog"
          : "custom-bg-video",
    },
    [
      _c("div", { staticClass: "flex justify-center items-center w-100 pa3" }, [
        _c(
          "div",
          {
            staticClass: "login justify-center pa3 br2",
            class: _vm.isStudio ? "bg-white" : "bg-adori-black",
          },
          [
            _vm.mode !== "LOADER"
              ? _c("div", [
                  _vm.mode !== "YOUTUBETERMS"
                    ? _c(
                        "div",
                        { staticClass: "flex flex-column items-center" },
                        [
                          _c("img", {
                            staticClass: "dark-svg",
                            attrs: {
                              src: _vm.isStudio ? _vm.darkLogo : _vm.whiteLogo,
                              width: "100",
                              height: "100",
                              alt: "",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "f4 mt2",
                              class: _vm.isStudio ? "black" : "white",
                            },
                            [_vm._v("Welcome to Adori")]
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        { staticClass: "flex flex-column items-center" },
                        [
                          _c("img", {
                            staticClass: "dark-svg",
                            attrs: {
                              src: require("@/assets/youtube/youtube-logo.svg"),
                              height: "70",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                ])
              : _vm._e(),
            _vm.mode === "SIGNUP"
              ? _c("div", { attrs: { id: "signup" } }, [
                  _c("div", { staticClass: "flex flex-column items-center" }, [
                    _c(
                      "div",
                      { staticClass: "w-100 flex justify-center mt3 f6" },
                      [
                        !_vm.isStudio
                          ? _c(
                              "button",
                              { staticClass: "pointer no-btn w-100" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex items-center w-100",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleGoogleSignIn()
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        height: "46",
                                        src: require("@/assets/publish/google_signup.png"),
                                        alt: "googl-logo",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "w-80 flex justify-center mt3",
                        class: _vm.isStudio ? "black" : "white",
                      },
                      [_vm._m(0)]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "w-80",
                        class: _vm.isStudio ? "black" : "white",
                      },
                      [
                        _vm.error
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "ba b--red w-100 bg-red white flex justify-center pa3 ml1 f6 br2 mt3",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.error) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c("BaseLabel", {
                              staticClass: "mb2",
                              attrs: {
                                for: "signupName",
                                text: "Name",
                                color: _vm.isStudio ? "black" : "white",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.$v.signup.signupName.$model,
                                expression: "$v.signup.signupName.$model",
                                modifiers: { trim: true },
                              },
                            ],
                            staticClass:
                              "bg-transparent ba br2 ml1 ph2 pv3 f6 w-100 mb1",
                            class: {
                              "b--red": _vm.$v.signup.signupName.$error,
                              black: _vm.isStudio,
                              white: !_vm.isStudio,
                            },
                            attrs: {
                              id: "signupName",
                              type: "text",
                              placeholder: "Enter your name",
                            },
                            domProps: {
                              value: _vm.$v.signup.signupName.$model,
                            },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleSignUpEnter.apply(
                                  null,
                                  arguments
                                )
                              },
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.signup.signupName,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _vm.$v.signup.signupName.$error
                            ? _c("span", { staticClass: "red f7 ml1" }, [
                                _vm._v("Name should be more than 4 character "),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "flex flex-column items-center" }, [
                    _c("div", { staticClass: "light-gray w-80" }, [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("BaseLabel", {
                            staticClass: "mb2",
                            attrs: {
                              for: "signupEmail",
                              text: "Email Address",
                              color: _vm.isStudio ? "black" : "white",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.signup.signupEmail.$model,
                              expression: "$v.signup.signupEmail.$model",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass:
                            "bg-transparent ba br2 ml1 ph2 pv3 f6 w-100 mb1",
                          class: {
                            "b--red": _vm.$v.signup.signupEmail.$error,
                            black: _vm.isStudio,
                            white: !_vm.isStudio,
                          },
                          attrs: {
                            id: "signupEmail",
                            type: "email",
                            placeholder: "Enter your email address",
                          },
                          domProps: { value: _vm.$v.signup.signupEmail.$model },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleSignUpEnter.apply(
                                null,
                                arguments
                              )
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.signup.signupEmail,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _vm.$v.signup.signupEmail.$error
                          ? _c("span", { staticClass: "red f7 ml1" }, [
                              _vm._v("Enter a valid email"),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex flex-column items-center" }, [
                    _c("div", { staticClass: "light-gray w-80" }, [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("BaseLabel", {
                            staticClass: "mb2",
                            attrs: {
                              for: "signupPassword",
                              text: "Password",
                              color: _vm.isStudio ? "black" : "white",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "relative" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.signup.signupPassword.$model,
                              expression: "$v.signup.signupPassword.$model",
                              modifiers: { trim: true },
                            },
                          ],
                          ref: "pass",
                          staticClass:
                            "bg-transparent ba br2 ml1 ph2 pv3 f6 w-100 mb1",
                          class: {
                            "b--red": _vm.$v.signup.signupPassword.$error,
                            black: _vm.isStudio,
                            white: !_vm.isStudio,
                          },
                          attrs: {
                            id: "signupPassword",
                            type: _vm.showPass ? "text" : "password",
                            placeholder: "Enter your password",
                          },
                          domProps: {
                            value: _vm.$v.signup.signupPassword.$model,
                          },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleSignUpEnter.apply(
                                null,
                                arguments
                              )
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.signup.signupPassword,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _vm.$v.signup.signupPassword.$error
                          ? _c("span", { staticClass: "red ml1 f7" }, [
                              _vm._v(
                                "Your password must be of 6 character or more"
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "i",
                          {
                            staticClass:
                              "absolute custom-position material-icons f3 dim pointer",
                            class: _vm.isStudio ? "black" : "white",
                            on: {
                              click: function ($event) {
                                _vm.showPass = !_vm.showPass
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.showPass ? "visibility_off" : "visibility"
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    !_vm.haveOtp
                      ? _c(
                          "div",
                          {
                            staticClass: "white-link gray mt3",
                            on: {
                              click: function ($event) {
                                _vm.haveOtp = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            I already have a one time password\n          "
                            ),
                          ]
                        )
                      : _c("div", { staticClass: "light-gray w-80 ease-in" }, [
                          _c(
                            "div",
                            {
                              staticClass: "flex justify-between items-center",
                            },
                            [
                              _c("BaseLabel", {
                                staticClass: "mb2",
                                attrs: {
                                  for: "otp",
                                  text: "One time verification code",
                                  color: _vm.isStudio ? "black" : "white",
                                },
                              }),
                              _c(
                                "i",
                                {
                                  staticClass: "material-icons f4 dim pointer",
                                  class: _vm.isStudio ? "black" : "white",
                                  on: {
                                    click: function ($event) {
                                      _vm.haveOtp = false
                                    },
                                  },
                                },
                                [_vm._v("remove_circle_outline")]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "relative" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.$v.otp.$model,
                                  expression: "$v.otp.$model",
                                  modifiers: { trim: true },
                                },
                              ],
                              ref: "otp",
                              staticClass:
                                "bg-transparent ba br2 ml1 ph2 pv3 f6 w-100 mb1",
                              class: _vm.$v.otp.$error
                                ? "b--red"
                                : _vm.isStudio
                                ? "black"
                                : "b--adori-gray",
                              attrs: {
                                id: "otp",
                                type: "text",
                                placeholder:
                                  "One time verification code received in your email",
                              },
                              domProps: { value: _vm.$v.otp.$model },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleSignUpEnter.apply(
                                    null,
                                    arguments
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.$v.otp,
                                    "$model",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                            _vm.$v.otp.$error
                              ? _c("span", { staticClass: "red ml1 f7" }, [
                                  _vm._v("Enter valid OTP"),
                                ])
                              : _vm._e(),
                            !_vm.$v.signup.signupEmail.$invalid
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "f6 ml1 gray underline dim",
                                    class: _vm.confirmLoader
                                      ? "not-allowed"
                                      : "pointer",
                                    on: {
                                      click: function ($event) {
                                        !_vm.confirmLoader
                                          ? _vm.resendOtp()
                                          : ""
                                      },
                                    },
                                  },
                                  [_vm._v("Resend OTP")]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-column w-80 items-center mt3 ml1",
                      },
                      [
                        _c("div", { staticClass: "f6 white" }, [
                          _vm._v(
                            "\n              By signing up, you agree to our\n              "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "link dim",
                              on: { click: _vm.openLink },
                            },
                            [_vm._v("Terms of service")]
                          ),
                          _vm._v(" and\n              "),
                          _c(
                            "span",
                            {
                              staticClass: "link dim",
                              on: { click: _vm.openLink },
                            },
                            [_vm._v("Privacy policy")]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "w-100 flex justify-center" },
                          [
                            _c("BaseButtonRed", {
                              ref: "sendOtp",
                              staticClass: "mt3 w-100",
                              attrs: {
                                text: _vm.haveOtp
                                  ? "Sign Up"
                                  : "Send Verification Code",
                                loading: _vm.signupLoader,
                                disabled: !_vm.signupSubmit || _vm.signupLoader,
                                onClick: _vm.haveOtp
                                  ? _vm.handleConfirm
                                  : _vm.handleSignUp,
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "flex w-100 mt2" }, [
                          _c("div", { staticClass: "f6 mt2 gray" }, [
                            _vm._v(
                              "\n                Existing user?\n                "
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "pointer underline dim",
                                class: _vm.isStudio ? "black" : "white",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleMode("LOGIN")
                                  },
                                },
                              },
                              [_vm._v("Login")]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.mode === "OTP"
              ? _c("div", { attrs: { id: "otp" } }, [
                  _c("div", { staticClass: "flex flex-column items-center" }, [
                    _c(
                      "p",
                      {
                        staticClass: "f3",
                        class: _vm.isStudio ? "black" : "white",
                      },
                      [_vm._v("Verification")]
                    ),
                  ]),
                  _c("div", { staticClass: "flex flex-column items-center" }, [
                    _c("div", { staticClass: "light-gray w-80" }, [
                      _c(
                        "div",
                        { staticClass: "flex justify-center ph5 tc" },
                        [
                          _c("BaseLabel", {
                            staticClass: "mb2",
                            attrs: {
                              for: "signupEmail",
                              text: "An email with verification code has been sent to your email address",
                              color: _vm.isStudio ? "black" : "white",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.otp.$model,
                              expression: "$v.otp.$model",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass:
                            "bg-transparent ba br2 ml1 ph2 pv3 f6 w-100 mb1",
                          class: {
                            "b--red": _vm.$v.otp.$error,
                            black: _vm.isStudio,
                            white: !_vm.isStudio,
                          },
                          attrs: {
                            id: "otp",
                            type: "text",
                            placeholder: "Enter the OTP",
                          },
                          domProps: { value: _vm.$v.otp.$model },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleOtpEnter.apply(null, arguments)
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.otp,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _vm.$v.otp.$error
                          ? _c("span", { staticClass: "red f7 ml1" }, [
                              _vm._v("Enter valid OTP"),
                            ])
                          : _vm._e(),
                        _vm.incorrectOtp
                          ? _c("span", { staticClass: "red f7 ml1" }, [
                              _vm._v("OOPS! Incorrect OTP. try again"),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-column items-center ml1 mt2",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "w-100 flex items-center" },
                              [
                                _c("BaseButtonRed", {
                                  staticClass: "mt3 w-100",
                                  attrs: {
                                    text: "Confirm OTP",
                                    loading: _vm.confirmLoader,
                                    disabled:
                                      !_vm.optCheck || _vm.confirmLoader,
                                    onClick: _vm.handleConfirm,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "gray underline dim mt3",
                                class: _vm.confirmLoader
                                  ? "not-allowed"
                                  : "pointer",
                                on: {
                                  click: function ($event) {
                                    !_vm.confirmLoader ? _vm.resendOtp() : ""
                                  },
                                },
                              },
                              [_vm._v("Resend OTP")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.mode === "LOGIN"
              ? _c("div", { attrs: { id: "login" } }, [
                  _c("div", { staticClass: "flex flex-column items-center" }, [
                    _c("div", { staticClass: "light-gray w-80" }, [
                      _vm.error
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "ba b--red w-100 bg-red white flex justify-center pa3 ml1 f6 br2 mt3",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.error) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "w-100 flex justify-center mt3 f6" },
                        [
                          !_vm.isStudio
                            ? _c(
                                "button",
                                { staticClass: "pointer no-btn w-100" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex items-center w-100",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleGoogleSignIn()
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          height: "46",
                                          src: require("@/assets/publish/google_signin.png"),
                                          alt: "googl-logo",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "w-100 flex justify-center mt3",
                          class: _vm.isStudio ? "black" : "white",
                        },
                        [_vm._m(1)]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("BaseLabel", {
                            staticClass: "mb2",
                            attrs: {
                              for: "signinEmail",
                              text: "Email Address",
                              color: _vm.isStudio ? "black" : "white",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.signin.signinEmail.$model,
                              expression: "$v.signin.signinEmail.$model",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass:
                            "bg-transparent ba br2 ml1 ph2 pv3 f6 w-100",
                          class: {
                            "b--red": _vm.$v.signin.signinEmail.$error,
                            black: _vm.isStudio,
                            white: !_vm.isStudio,
                          },
                          attrs: {
                            id: "signinEmail",
                            type: "email",
                            placeholder: "Enter your email address",
                          },
                          domProps: { value: _vm.$v.signin.signinEmail.$model },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleSignInEnter.apply(
                                null,
                                arguments
                              )
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.signin.signinEmail,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _vm.$v.signin.signinEmail.$error
                        ? _c("span", { staticClass: "red f7 ml1" }, [
                            _vm._v("Enter valid email"),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex flex-column items-center" }, [
                    _c("div", { staticClass: "light-gray w-80" }, [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("BaseLabel", {
                            staticClass: "mb2",
                            attrs: {
                              for: "Name",
                              text: "Password",
                              color: _vm.isStudio ? "black" : "white",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "relative" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.signin.signinPassword.$model,
                              expression: "$v.signin.signinPassword.$model",
                              modifiers: { trim: true },
                            },
                          ],
                          ref: "pass",
                          staticClass:
                            "bg-transparent ba br2 ml1 ph2 pv3 f6 w-100 mb1",
                          class: {
                            "b--red": _vm.$v.signin.signinPassword.$error,
                            black: _vm.isStudio,
                            white: !_vm.isStudio,
                          },
                          attrs: {
                            type: _vm.showPass ? "text" : "password",
                            placeholder: "Enter your password",
                          },
                          domProps: {
                            value: _vm.$v.signin.signinPassword.$model,
                          },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleSignInEnter.apply(
                                null,
                                arguments
                              )
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.signin.signinPassword,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                        _vm.$v.signin.signinPassword.$error
                          ? _c("span", { staticClass: "red ml1 f7" }, [
                              _vm._v("Cannot be empty"),
                            ])
                          : _vm._e(),
                        _c(
                          "i",
                          {
                            staticClass:
                              "absolute custom-position material-icons f3 dim pointer",
                            class: _vm.isStudio ? "black" : "white",
                            on: {
                              click: function ($event) {
                                _vm.showPass = !_vm.showPass
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.showPass ? "visibility_off" : "visibility"
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-column items-center w-80 mt3 ml2",
                      },
                      [
                        _c("BaseButtonRed", {
                          staticClass: "mt3 w-100",
                          attrs: {
                            text: "Sign In",
                            loading: _vm.signinLoader,
                            disabled: !_vm.signinSubmit || _vm.signinLoader,
                            onClick: _vm.handleSignIn,
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "w-100 flex justify-between" },
                          [
                            _c("div", { staticClass: "f6 mt3" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "gray underline dim f6 pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleMode("FORGOTPASSWORD")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  Forgot Password?\n                "
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "f6 mt3 gray" }, [
                              _vm._v(
                                "\n                New user?\n                "
                              ),
                              !_vm.isStudio
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "f6 pointer underline dim",
                                      class: _vm.isStudio ? "black" : "white",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleMode("SIGNUP")
                                        },
                                      },
                                    },
                                    [_vm._v("Signup")]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.mode === "FORGOTPASSWORD"
              ? _c("div", { attrs: { id: "forgotpassword" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "flex flex-column items-center",
                      class: _vm.isStudio ? "black" : "white",
                    },
                    [
                      _c("p", { staticClass: "f3" }, [
                        _vm._v("Forgot Password"),
                      ]),
                      _vm._m(2),
                    ]
                  ),
                  _c("div", { staticClass: "flex flex-column items-center" }, [
                    _c("div", { staticClass: "light-gray w-80" }, [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("BaseLabel", {
                            staticClass: "mb2",
                            attrs: {
                              for: "signinEmail",
                              text: "Email Address",
                              color: _vm.isStudio ? "black" : "white",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.$v.forgotpassword.email.$model,
                              expression: "$v.forgotpassword.email.$model",
                              modifiers: { trim: true },
                            },
                          ],
                          staticClass:
                            "bg-transparent ba br2 ml1 ph2 pv3 f6 w-100",
                          class: _vm.isStudio ? "black" : "white",
                          attrs: {
                            id: "signinEmail",
                            type: "email",
                            placeholder: "Enter your email address",
                          },
                          domProps: {
                            value: _vm.$v.forgotpassword.email.$model,
                          },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleForgotPasswordEnter.apply(
                                null,
                                arguments
                              )
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.forgotpassword.email,
                                "$model",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _vm.$v.forgotpassword.email.$error
                        ? _c("span", { staticClass: "red ml1 f7" }, [
                            _vm._v("Enter valid email"),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "flex flex-column items-start ml1 mt2" },
                        [
                          _c(
                            "div",
                            { staticClass: "w-100 flex items-center" },
                            [
                              _c("BaseButtonRed", {
                                staticClass: "mt3 w-100",
                                attrs: {
                                  text: "Submit",
                                  loading: _vm.passwordLoader,
                                  disabled: !_vm.forgotPasswordSubmit,
                                  onClick: _vm.changePassword,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "f6 w-100 flex justify-center mt3" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "gray underline f7 dim pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleMode("LOGIN")
                                    },
                                  },
                                },
                                [_vm._v("Back to login")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.mode === "YOUTUBETERMS"
              ? _c("div", { staticClass: "flex justify-center mt3" }, [
                  _c("div", { staticClass: "privacy-terms w-80 white br2" }, [
                    _vm._m(3),
                    _c("p", [
                      _vm._v(
                        "\n            Adori will use YouTube Api Services to upload videos and access the following information from your\n            YouTube account\n          "
                      ),
                    ]),
                    _vm._m(4),
                    _vm._m(5),
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-100 flex justify-center items-center mt4 mb3",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "google-btn",
                            on: { click: _vm.handleYoutubeLogin },
                          },
                          [
                            _c("img", {
                              attrs: {
                                height: "46",
                                src: require("@/assets/publish/google_signin.png"),
                                alt: "googl-logo",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.mode === "LOADER"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-column justify-center items-center w-100",
                    staticStyle: { height: "614px" },
                  },
                  [_c("div", { staticClass: "bar-loader" })]
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "flex w-100" }, [
      _c("hr", { staticClass: "w-100 mr3 b--silver" }),
      _vm._v("\n              OR\n              "),
      _c("hr", { staticClass: "w-100 ml3 b--silver" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "flex w-100" }, [
      _c("hr", { staticClass: "w-100 mr3 b--silver" }),
      _vm._v("\n                OR\n                "),
      _c("hr", { staticClass: "w-100 ml3 b--silver" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "f6" }, [
      _vm._v("\n            Enter your email address to reset your password."),
      _c("br"),
      _vm._v(
        "\n            You will receive an email with instructions.\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            By using this destination, Adori and you agree to\n            "
      ),
      _c(
        "a",
        {
          staticClass: "blue link",
          attrs: { href: "https://www.youtube.com/t/terms", target: "_blank" },
        },
        [_vm._v("YouTube's Terms of Service,")]
      ),
      _c(
        "a",
        {
          staticClass: "blue link",
          attrs: {
            href: "https://developers.google.com/youtube/terms/api-services-terms-of-service",
            target: "_blank",
          },
        },
        [_vm._v("YouTube Api Services Terms of Service")]
      ),
      _vm._v("\n            and\n            "),
      _c(
        "a",
        {
          staticClass: "blue link",
          attrs: {
            href: "http://www.google.com/policies/privacy",
            target: "_blank",
          },
        },
        [_vm._v("Google's Privacy Policy")]
      ),
      _vm._v(".\n          "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v("Name and email on account/channel(stored for display)"),
      ]),
      _c("li", [_vm._v("Profile picture (displayed on this page)")]),
      _c("li", [
        _vm._v(
          "IDs of videos creating through this destination (stored for generating statistics)"
        ),
      ]),
      _c("li", [
        _vm._v("Video analytics (will be displayed in Adori Insights)"),
      ]),
      _c("li", [
        _vm._v(
          "Please note: Adori will store Youtube data longer than 30 calendar days"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            You may revoke access to your YouTube account at any time from this url:\n            "
      ),
      _c(
        "a",
        {
          staticClass: "blue link",
          attrs: {
            href: "https://security.google.com/settings/security/permissions",
            target: "_blank",
          },
        },
        [_vm._v("https://security.google.com/settings/security/permissions")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }